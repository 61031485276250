import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import DisplayContentHeader from "./DisplayContentHeader";
import { HTMLContent } from "./Content";
import DisplayDate from "./DisplayDate";
class AuthorSummaryTemplate extends React.Component {
  render() {
    const { data, author } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    let authorDetails = posts.filter(
      (post) => post.node.frontmatter.name === author
    );

    authorDetails = authorDetails[0].node;
    let smallImage = authorDetails.frontmatter.images.smallImage;
    if (!smallImage)
      smallImage = authorDetails.frontmatter.images.smallImageUrl;

    if (authorDetails) {
      return (
        <div className="content author-summary ">
          {smallImage ? (
            <Link
              to={authorDetails.fields.slug}
              title={authorDetails.frontmatter.name}
            >
              <figure className="image is-inline-block author-icon-small is-pulled-right pt-0 mt-0 is-hidden-mobile">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: smallImage,
                    alt: authorDetails.frontmatter.name,
                    className: "author-image",
                  }}
                />
              </figure>
            </Link>
          ) : null}
          About The Author:
          <DisplayContentHeader
            title={authorDetails.frontmatter.name}
            slug={authorDetails.fields.slug}
          />
          <div>
            {<HTMLContent content={authorDetails.html} /> || (
              <p>{authorDetails.frontmatter.summary}</p>
            )}
          </div>
          <p className="smaller">
            Contributing since{" "}
            <DisplayDate date={authorDetails.frontmatter.creationDate} />.
          </p>
        </div>
      );
    } else {
      return null;
    }
  }
}

AuthorSummary.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  author: PropTypes.string,
};

export default function AuthorSummary(props) {
  const { author } = props;

  return (
    <StaticQuery
      query={graphql`
        query AuthorSummaryQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___creationDate] }
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
            }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                html
                frontmatter {
                  name
                  creationDate
                  summary
                  images {
                    smallImage {
                      childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                      }
                    }
                    smallImageUrl
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <AuthorSummaryTemplate data={data} author={author} />
      )}
    />
  );
}
