import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { v4 } from "uuid";
import DisplayDate from "./DisplayDate";
export default function DisplayRelated(props) {
  let { related } = props;

  // trim down to the first four results

  if (related) {
    if (related.length > 4) {
      related = related.slice(0, 3);
    }

    return (
      <React.Fragment key={v4()}>
        <div className="columns is-multiline">
          {related.map((item) => {
            item.frontmatter.slug = item.fields.slug;
            item = item.frontmatter;

            return (
              <React.Fragment key={v4()}>
                <div className="column is-6">
                  <span>
                    <h3 className="title is-size-5 has-text-weight-bold is-bold-light mb-0">
                      <Link to={item.slug} title={item.title}>
                        {item.title ? item.title : item.name}
                      </Link>
                    </h3>

                    <h4 className="title is-size-5 has-text-weight-normal is-bold-light mt-2 mb-0">
                      <DisplayDate date={item.creationDate} />
                      <br />
                      By {item.author}
                    </h4>
                  </span>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

DisplayRelated.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};
