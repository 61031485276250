import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";

import { getImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { DiscussionEmbed } from "disqus-react";

import DisplayContentHeader from "../components/DisplayContentHeader";
import FullWidthImage from "../components/FullWidthImage";
import AuthorSummary from "../components/AuthorSummary";

import Summary from "../components/Summary";

import DisplayKeepReadingRelated from "../components/DisplayKeepReadingRelated";
import { v4 } from "uuid";

function returnAuthorSlug(author, authorCollection) {
  let k = null;
  let v = null;
  let results = [];

  authorCollection.forEach((author) => {
    k = author.frontmatter.name;
    v = author.fields.slug;
    results[k] = v;
  });
  return results[author];
}

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  summary,
  category,
  tags,
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  helmet,
  slug,
  id,
  authors,
  config,
  relatedPosts,
  photos,
  previewContent,
  timeToRead,
}) => {
  const PostContent = HTMLContent || Content;

  let heroImage, footerImage, smallImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;

  if (images && images.smallImage) smallImage = images.smallImage;

  let showTitle = options.showTitle;
  let showSummary = options.showSummary;
  let showTags = options.showTags;
  let showComments = options.showComments;
  let showAuthorSummary = options.showAuthorSummary;
  let showPrevNext = options.showPrevNext;

  let showRelated = options.showRelated;
  let showPopular = options.showPopular;

  if (config) config = config.frontmatter;

  if (
    config &&
    config.overrides &&
    config.overrides.showTitle &&
    config.overrides.showTitle.enabled
  ) {
    showTitle = config.overrides.showTitle.value;
  }
  if (
    config &&
    config.overrides &&
    config.overrides.showSummary &&
    config.overrides.showSummary.enabled
  ) {
    showSummary = config.overrides.showSummary.value;
  }
  if (
    config &&
    config.overrides &&
    config.overrides.showTags &&
    config.overrides.showTags.enabled
  ) {
    showTags = config.overrides.showTags.value;
  }
  if (
    config &&
    config.overrides &&
    config.overrides.showComments &&
    config.overrides.showComments.enabled
  ) {
    showComments = config.overrides.showComments.value;
  }
  if (
    config &&
    config.overrides &&
    config.overrides.showAuthorSummary &&
    config.overrides.showAuthorSummary.enabled
  ) {
    showAuthorSummary = config.overrides.showAuthorSummary.value;
  }
  if (
    config &&
    config.overrides &&
    config.overrides.showPrevNext &&
    config.overrides.showPrevNext.enabled
  ) {
    showPrevNext = config.overrides.showPrevNext.value;
  }

  if (
    config &&
    config.overrides &&
    config.overrides.showRelated &&
    config.overrides.showRelated.enabled
  ) {
    showRelated = config.overrides.showRelated.value;
  }

  if (
    config &&
    config.overrides &&
    config.overrides.showPopular &&
    config.overrides.showPopular.enabled
  ) {
    showPopular = config.overrides.showPopular.value;
  }

  if (!process.env.GATSBY_DISQUS_NAME) showComments = false;
  let disqusConfig;
  if (showComments) {
    disqusConfig = {
      shortname: process.env.GATSBY_DISQUS_NAME,
      config: { identifier: slug, title },
    };
  }

  let authorSlug;

  if (authors) {
    authors = authors.nodes;
    authorSlug = returnAuthorSlug(author, authors);
  }

  return (
    <div>
      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        {helmet || ""}

        <div className="container">
          <div className="content">
            <div className="content">
              <DisplayContentHeader
                title={title}
                subtitle={subtitle}
                subtitleClass="has-text-gray"
                parent="Blog"
                parentSlug="/blog"
                date={creationDate}
              />
            </div>
            {showSummary && summary ? (
              <div className="content">
                <Summary
                  summary={summary}
                  image={previewContent ? smallImage : images.smallImage}
                  alt={title}
                  category={category}
                  relatedPerson={author}
                  relatedPersonType="Author"
                  relatedPersonSlug={authorSlug}
                  timeToRead={timeToRead}
                />
              </div>
            ) : null}
            <div className="content blog-post">
              <div className="content">
                {previewContent ? (
                  <>{previewContent}</>
                ) : (
                  <PostContent content={content} />
                )}
              </div>
              {showTags && tags && tags.length ? (
                <div>
                  <h4>Tags:</h4>
                  <ul className="taglist">
                    {tags.map((tag) => (
                      <React.Fragment key={v4()}>
                        <li>
                          <Link to={`/tag/${kebabCase(tag)}/`}>
                            <span className="tag is-medium">{tag}</span>
                          </Link>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              ) : null}

              {photos && photos.showGallery && photos.photo ? (
                <>
                  <div className="content">
                    <h4>
                      Related {category ? <>{category}</> : null} Photo Gallery:
                    </h4>
                    <div>
                      <Link
                        to={`${slug}gallery/`}
                        title={`${title} Photo Gallery`}
                      >
                        {title} Photos
                      </Link>
                    </div>
                  </div>
                </>
              ) : null}

              {showAuthorSummary ? <AuthorSummary author={author} /> : null}
            </div>
            {showComments ? (
              <div className="content">
                <DiscussionEmbed {...disqusConfig} />
              </div>
            ) : null}

            {showPrevNext || showRelated ? (
              <>
                <hr />
                <DisplayKeepReadingRelated id={id} related={relatedPosts} />
              </>
            ) : null}

            {showPopular ? null : null}
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  helmet: PropTypes.object,
  title: PropTypes.string,
  summary: PropTypes.string,
  category: PropTypes.string,
  tags: PropTypes.array,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  slug: PropTypes.string,
  id: PropTypes.string,
  authors: PropTypes.object,
  config: PropTypes.object,
  relatedPosts: PropTypes.array,
  timeToRead: PropTypes.number,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { defaultPost } = data;
  const { authors } = data;
  const { config } = data;

  let imageSrc;

  if (post.frontmatter.images && post.frontmatter.images.hero) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  } else {
    imageSrc =
      defaultPost.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }

  if (!post.frontmatter.titles) post.frontmatter.titles = {};

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        helmet={
          <Seo
            title={`Blog > ${post.frontmatter.category} > ${post.frontmatter.title}`}
            keywords={`${post.frontmatter.category.toLowerCase()} blog, ${post.frontmatter.category.toLowerCase()}`}
            summary={
              post.frontmatter.summary ? post.frontmatter.summary : post.excerpt
            }
            image={imageSrc}
            article={true}
            tags={post.frontmatter.tags}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        summary={post.frontmatter.summary}
        category={post.frontmatter.category}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        images={post.frontmatter.images}
        slug={post.fields.slug}
        id={post.id}
        authors={authors}
        config={config}
        relatedPosts={post.relatedPosts}
        photos={post.frontmatter.photos}
        timeToRead={post.timeToRead}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    authors: PropTypes.object,
    site: PropTypes.object,
    config: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
        property {
          url
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      excerpt(pruneLength: 400)
      timeToRead
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        summary
        category
        tags
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
          showSummary
          showTags
          showComments
          showAuthorSummary
          showPrevNext
          showRelated
          showPopular
        }
        author
        creationDate
        photos {
          showGallery
          photo {
            image {
              id
            }
          }
        }
      }
      relatedPosts {
        fields {
          slug
        }
        frontmatter {
          title
          creationDate
          author
        }
      }
    }
    defaultPost: markdownRemark(
      frontmatter: { advanced: { templateKey: { eq: "blog-page" } } }
    ) {
      frontmatter {
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "blog" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        overrides {
          showTitle {
            enabled
            value
          }
          showSummary {
            enabled
            value
          }
          showTags {
            enabled
            value
          }
          showComments {
            enabled
            value
          }
          showAuthorSummary {
            enabled
            value
          }
          showPrevNext {
            enabled
            value
          }
        }
      }
    }
  }
`;
//cf3f4add-b6ab-51ed-a0f9-43a8901f01d0
