import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import DisplayDate from "./DisplayDate";
export default function DisplayPrevNext(props) {
  const {
    previous = {},
    next = {},
    beginMessage = "This is our first entry.",
    endMessage = "This is our last entry.",
  } = props;

  if (previous || next) {
    return (
      <React.Fragment>
        <div className="split-fiftyfifty">
          <div className="columns">
            <div className="column is-6 left-side">
              {previous ? (
                <span>
                  <h3 className="title is-size-5 has-text-weight-bold is-bold-light mb-0">
                    <Link
                      to={previous.fields.slug}
                      title={`Previous: ${previous.frontmatter.title}`}
                    >
                      {previous.frontmatter.title
                        ? previous.frontmatter.title
                        : previous.frontmatter.name}
                    </Link>
                  </h3>

                  <h4 className="title is-size-6 has-text-weight-normal is-bold-light mt-2 mb-0">
                    <DisplayDate date={previous.frontmatter.creationDate} />
                    <br />
                    By {previous.frontmatter.author}
                  </h4>
                </span>
              ) : (
                <span>
                  <h6 className="title is-size-6 has-text-weight-normal is-bold-light">
                    {beginMessage}
                  </h6>
                </span>
              )}
            </div>
            <div className="column is-6 right-side">
              {" "}
              {next ? (
                <span>
                  <h3 className="title is-size-5 has-text-weight-bold is-bold-light mb-0">
                    <Link
                      to={next.fields.slug}
                      title={`Next: ${next.frontmatter.title}`}
                    >
                      {next.frontmatter.title
                        ? next.frontmatter.title
                        : next.frontmatter.name}
                    </Link>
                  </h3>

                  <h4 className="title is-size-6 has-text-weight-normal is-bold-light mt-2 mb-0">
                    <DisplayDate date={next.frontmatter.creationDate} />
                    <br />
                    By {next.frontmatter.author}
                  </h4>
                </span>
              ) : (
                <span>
                  <h6 className="title is-size-6 has-text-weight-normal is-bold-light">
                    {endMessage}
                  </h6>
                </span>
              )}{" "}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

DisplayPrevNext.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};
