import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { kebabCase } from "lodash";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import pluralize from "pluralize";
const Summary = ({
  summary,
  category = false,
  image = false,
  alt = "",
  relatedPerson = "",
  relatedPersonType = "",
  relatedPersonSlug = "",
  timeToRead = "",
}) => {
  return (
    <>
      <div className="content">
        <div className="card is-shadowless border">
          <div className="card-content pr-4">
            {image ? (
              <figure className="image is-inline-block summary-icon is-pulled-right pt-0 m-0 is-hidden-mobile">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: image,
                    alt: "Summary Icon",
                    className: "summary-icon",
                  }}
                />
              </figure>
            ) : null}
            <h5 className="mt-0">Summary:</h5>
            <p className="mt-0">{summary}</p>
          </div>
          <footer className="card-footer ">
            {category ? (
              <>
                <p className="summary card-footer-item mt-2 mb-2">
                  <span>
                    Category:{" "}
                    <Link to={`/category/${kebabCase(category)}/`}>
                      {category}
                    </Link>
                  </span>
                </p>
              </>
            ) : null}

            {timeToRead ? (
              <>
                <p className="summary card-footer-item mt-2 mb-2">
                  <span>
                    Read Time: {timeToRead} {pluralize("minute", timeToRead)}
                  </span>
                </p>
              </>
            ) : null}

            {relatedPerson && relatedPersonType ? (
              <>
                {relatedPersonSlug ? (
                  <>
                    <p className="summary card-footer-item mt-2 mb-2">
                      <span>
                        {relatedPersonType}:{" "}
                        <Link to={relatedPersonSlug}>{relatedPerson}</Link>
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="summary card-footer-item mt-2 mb-2">
                      <span>
                        {relatedPersonType}: {relatedPerson}
                      </span>
                    </p>
                  </>
                )}
              </>
            ) : null}
          </footer>
        </div>
      </div>
    </>
  );
};

Summary.propTypes = {
  url: PropTypes.string,
};

export default Summary;
